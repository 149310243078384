@import "../../../variables";
@import "../../../mixins";
@import "../../../helperClasses";


.vertical-alignment {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.region {
    margin-bottom: 0;
}
.image-with-text {
    @include top-bottom-padding;
    picture {
        img {
            position: relative;
            z-index: 8;
        }
    }
    .text-block {
        .heading-with-cta {
            .text-block-heading {
                @include media-breakpoint-down(sm) {
                    margin: 20px 0;
                }
            }
        }

        .heading-only {
            height: 100%;
        }
    }

    .mobile-heading {
        display: none;
    }

    .layout-3-mobile-only {
        display: none;
    }
    // .imagetext-video {
    //     height: 300px;
    //     @include media-breakpoint-up(md) {
    //         height: 250px;
    //     }
    //     @include media-breakpoint-up(lg) {
    //         height: 350px;
    //     }
    //     @include media-breakpoint-up(xl) {
    //         height: 450px;
    //     }
    //     @include media-breakpoint-up(xxl) {
    //         height: 632px;
    //     }
    //     video {
    //         height: 100%;
    //         object-fit: cover;
    //     }
    // }
    &.image-text-Layout-3 {
        .text-block {
            // @include media-breakpoint-down(md) {
            //     width: 100%;
            // }
            // @include media-breakpoint-only(sm) {
            //     width: 100%;
            //     .text-block-heading {
            //         display: none;
            //     }
            // }
        }
        // .small-image {
        //     @include media-breakpoint-only(sm) {
        //         width: 100%;
        //     }
        // }
        .desktop-only {
            @include media-breakpoint-only(sm) {
                display: none;
            }
        }
        .layout-3-mobile-only {
            @include media-breakpoint-only(sm) {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
        }
        .heading-with-cta {
            .layout-bottom-block {
                @include media-breakpoint-up(md) {
                    padding-bottom: 40px;
                }
            }
        }
    }
    
    &.image-text-Layout-4 {
        .small-image {
            .image-block {
                @include media-breakpoint-down(sm) {
                    display: flex;
                    align-items: flex-end;
                    h2 {
                        padding-left: 25px;
                    }
                    .mobile-heading {
                        display: block;
                    }
                }
            }   
        }
        .heading-only {
            .text-block-heading {
                display: none;
                @include media-breakpoint-up(sm) {
                    display: block;
                }
            }
        }
        .heading-with-cta {
            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: $font-weight-extralight;
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 28px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    &.image-text-Layout-9 {
        .title {
            @include media-breakpoint-up(md) {
                margin-bottom: 58px;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 70px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 80px;
            }
            @include media-breakpoint-up(xxl) {
                margin-bottom: 100px;
            }
        }
        .img-block-wrapper {
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
            @include media-breakpoint-only(sm) {
                margin-bottom: 25px;
            }
        }
    }

    &.image-with-text-without-bg {
        * {
            color: $brand-secondary;
        }
        a {
            color: $brand-secondary;
            &:hover {
                border-color: $brand-secondary;
                color: $brand-secondary;
            }

            &:after,
            &:hover:after {
                border-color: $brand-secondary;
            }
        }
        &[class*="text--"] {
            * {
                color: inherit;
            }
            a {
                color: inherit;
                &:hover {
                    border-color: inherit;
                    color: inherit;
                }
    
                &:after,
                &:hover:after {
                    border-color: inherit;
                }
            }
        }
    }
    
    &[class*="text--"] {
        * {
            color: inherit;
        }
        a {
            &:hover {
                border-color: inherit;
                color: inherit;
            }

            &:after,
            &:hover:after {
                border-color: inherit;
            }
        }
    }


    /*-- COLOR BLOCK STYLE STARTS --*/

    .color-block-mobile-slider {
        position: relative;
        z-index: 9;
    
        .mobile-slider-content {
            .heading {
                @include media-breakpoint-down(md) {
                    margin-top: 20px;
                }
                // @include media-breakpoint-down(sm) {
                //     margin-top: 20px;
                // }
            }
        }
    }

    &.Colorblock-1 {
        .color-block-desktop {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            .mobile-image-carousel {
                picture {
                    object-position: bottom;
                }
            }
        }
        .heading {
            h2 {
                @include media-breakpoint-up(lg) {
                    margin-top: 50px;
                }
            }
        }
    }
    &.Colorblock-2 {
        .color-text-wrapper {
            .colorblock-heading,
            .color-text-block  {
                * {
                    color: $maroon;
                }
                a {
                    color: $maroon;
                    &:hover {
                        border-color: $maroon;
                        color: $maroon;
                    }
        
                    &:after,
                    &:hover:after {
                        border-color: $maroon;
                    }
                }
                .custom-text-color {
                    * {
                        color: inherit;
                    }
                    a {
                        color: inherit;
                        &:hover {
                            border-color: inherit;
                            color: inherit;
                        }
            
                        &:after,
                        &:hover:after {
                            border-color: inherit;
                        }
                    }
                }
            }
        }
    }
    &.Colorblock-3 {
        .left-text-block {
            * {
                color: $green;
            }
            a {
                color: $green;
                &:hover {
                    border-color: $green;
                    color: $green;
                }
    
                &:after,
                &:hover:after {
                    border-color: $green;
                }
            }
            .custom-text-color {
                * {
                    color: inherit;
                }
                a {
                    color: inherit;
                    &:hover {
                        border-color: inherit;
                        color: inherit;
                    }
        
                    &:after,
                    &:hover:after {
                        border-color: inherit;
                    }
                }
            }
        }
        &[class*="text--"] {
            .left-text-block {
                * {
                    color: inherit;
                }
                a {
                    color: inherit;
                    &:hover {
                        border-color: inherit;
                        color: inherit;
                    }
        
                    &:after,
                    &:hover:after {
                        border-color: inherit;
                    }
                }
            }
        }
        .vertical-alignment {
            align-items: flex-start;
        }
        h1.Title {
            @include header(3);
        }
    }
    &.Colorblock-4 {
        .left-text-block {
            margin-bottom: 20px;
        }
    }
    .mobile-left-text-block {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    &.Colorblock-3 {
        @include media-breakpoint-down(md) {
            .text-block {
                order: 1;
                margin-bottom: 20px;
            }
            .small-image {
                order: 3;
            }
        }
        
        .left-text-block.vertical-alignment {
            h3, h4, h1.h3, h1.Title {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
        
        .color-block-desktop {
            .small-image {
                @include media-breakpoint-only(sm) {
                    width: 100%;
                }
            }
            .text-block {
                @include media-breakpoint-only(sm) {
                    width: 100%;
                    display: flex;
                }
                .mobile-left-text-block {
                    margin-bottom: 20px;
                    @include media-breakpoint-only(sm) {
                        width: 50%;
                    }
                }
                .banner-image-block {
                    @include media-breakpoint-only(sm) {
                        width: 50%;
                    }
                }
            }
        }
        .text-bottom {
            @include media-breakpoint-up(md) {
                padding-top: 40px;
            }
        }
    }
    &.Colorblock-4 {
        .color-block-desktop {
            .text-block {
                .banner-image-block {
                    @include media-breakpoint-only(sm) {
                        width: 100%;
                    }
                }
            }   
        }
        .img-info-overlay-parent {
            .img-info-overlay {
                .info-overlay-container {
                    .info-overlay-icon {
                        display: none;
                    }
                }
            }
            &:hover {
                .info-overlay-download-icon {
                    opacity: 1;
                }
            }
            .info-overlay-container {
                display: none;
            }
            .info-overlay-download-icon {
                @include media-breakpoint-down(md) {
                    opacity: 1;
                }
            }
        }
    }
    /*-- COLOR BLOCK STYLE ENDS --*/
    .top-text {
        * {
            font-weight: $font-weight-light;
        }
        a {
            font-weight: $font-weight-semibold;
        }
    }
    &.article-block {
        .image-description {
            margin-top: 20px;
            .image-description-text {
                font-size: 18px;
                line-height: 28px;
                font-weight: 300;
            }
        }
        &.contentTile-block {
            padding: 0 0 20px;
            .image-description {
                a {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
    // &.image-text-Layout-7 {
    //     h2, h1 {
    //         @include media-breakpoint-down(md) {
    //             font-size: 40px;
    //             line-height: 50px;
    //         }
    //     }
    // }
    &.image-text-Layout-8 {
        .img-info-overlay-parent {
            .img-info-overlay {
                @include media-breakpoint-up(lg) {
                    width: 100%
                }
                .info-overlay-container {
                    .info-overlay-text-block {
                        .social-icons {
                            padding: 15px 15px 14px 55px;
                        }
                    }
                }
            }
        }
        h1.Title {
            @include header(5);
        }
    }  
    &.image-text-Layout-7 {
        .content-part {
            h2 {
                @include media-breakpoint-up(lg) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }
        }
    }
}
.article-heading {
    @include top-margin;
    * {
        color: $brand-secondary;
        font-weight: $font-weight-semibold;
        letter-spacing: 0.25px;
    }
}

.video-block {
    @include top-bottom-padding;
    p {
        strong {
            font-weight: $font-weight-semibold;
        }
    }
    &.image-text-Layout-5 {
        .video-player {
            @include bottom-margin;
        }
        * {
            color: $brand-secondary;
        }
        a {
            color: $brand-secondary;
            &:hover {
                border-color: $brand-secondary;
                color: $brand-secondary;
            }
    
            &:after,
            &:hover:after {
                border-color: $brand-secondary;
            }
        }
        &[class*="text--"] {
            * {
                color: inherit;
            }
            a {
                color: inherit;
                &:hover {
                    border-color: inherit;
                    color: inherit;
                }
    
                &:after,
                &:hover:after {
                    border-color: inherit;
                }
            }
        }
        .custom-text-color {
            * {
                color: inherit;
            }
            a {
                &:after {
                    border-color: inherit;
                }
                &:hover {
                    color: inherit;
                    border-color: inherit;
                    &:after {
                        border-color: inherit;
                    }
                }
            }
        }
        .title-margin {
            @include media-breakpoint-up(lg) {
                @include bottom-margin;
            }
        }
        .vimeo-video-frame {
            max-height: 330px;
        }

    }
    &.image-text-Layout-6 {
        .text-block {
            b {
                font-weight: 600;
            }
            @include media-breakpoint-down(lg) {
                @include top-margin;
            }
            .text-block-heading {
                margin-bottom: 20px;
            }
        }
        .vimeo-video-frame {
            max-height: 200px;
            @include media-breakpoint-up(sm) {
                max-height: 300px;
            }
            @include media-breakpoint-up(md) {
                max-height: 460px;
            }
            @include media-breakpoint-up(lg) {
                max-height: 580px;
            }
        }
    }
    .custom-text-color {
        * {
            color: inherit;
        }
        a {
            &:after {
                border-color: inherit;
            }
            &:hover {
                color: inherit;
                border-color: inherit;
                &:after {
                    border-color: inherit;
                }
            }
        }
    }
}
.colorblock-wrapper {
    position: relative;
    margin: 0;
    z-index: 1;
    .colorblock-heading,
    .color-text-block {
        margin-top: 20px;
    }
    .text-block,
    .colorblock-heading,
    .color-text-block {
        z-index: 9;
    }

    &[class*="text--"] {
        .color-text-wrapper {
            .colorblock-heading,
            .color-text-block {
                &>* {
                    color: inherit;
                }
                a {
                    color: inherit;
                    &:hover {
                        border-color: inherit;
                        color: inherit;
                    }
        
                    &:after,
                    &:hover:after {
                        border-color: inherit;
                    }
                }
            }
        }
        .left-text-block > * {
            color: inherit;
        }
    }

    .small-image, .large-image {
        z-index: 9;
    }
    .left-text-block {
        h3, h1.h3 {
            font-weight: 600;
        }
    }
    .color-block-mobile-slider {
        display: none;
        .slick-dotted.slick-slider {
            margin-bottom: 0;
        }
        .content-part {
            @include media-breakpoint-down(md) {
                margin-top: 13px;
            }
        }
    }
    &.Colorblock-1 {
        .color-block-mobile-slider { 
            @include media-breakpoint-down(md) {
                display: flex;
            }
            .mobile-image-carousel {
                display: none;
                @include media-breakpoint-down(sm) {
                    display: flex;
                }
            }
            .colorblock-img {
                display: none;
                @include media-breakpoint-only(sm) {
                    display: flex;
                }
            }
        }
        .tablet-heading {
            margin-top: 29px;
            position: relative;
            z-index: 9;
        }
    }
    // &.Colorblock-1, 
    // &.Colorblock-2 {
    //     h1.Title {
    //         @include header(2);
    //     }
    // }
    &.Colorblock-2,
    &.Colorblock-1 {
        h2, h1.Title {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: normal;
            @include media-breakpoint-up(lg) {
                font-size: 30px;
                line-height: 40px;
                letter-spacing: 0.1px;
            }
        }
    }
}

.colorblock-bg {
    position: relative;
    @include bottom-margin;
    .region {
        position: static;
    }
}

.backgroundcolor-section {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70%;
    width: 100%;
    font-size: 0;
    div {
        height: 100%;
        font-size: 18px;
        color: transparent;
    }
    &.Colorblock-1-background {
        div {
            background-color: $celeste;
        }
    }
    &.Colorblock-2-background {
        div {
            background-color: $ligh-brown;
        }
    }
    &.Colorblock-3-background {
        height: 100%;
        div {
            background-color: $sugarcane;
        }
    }
    &[class*='background--'] {
        div {
            background-color: inherit;
        }
    }
}

.content-part {
    margin-top: 20px;
}

.image-with-text {
    p {
        strong {
            font-weight: $font-weight-semibold;
        }
    }
    .img-block-wrapper {
        @include media-breakpoint-only(sm) {
            // display: flex;
            // align-items: flex-end;
            // margin-top: 20px;
        }
    }
    

    &.image-text-Layout-1 {
        .text-block {
            // @include media-breakpoint-up(xxl) {
            //     padding-right: 7%;
            // }
        }
    }
    /*-- Layout 1 style ends --*/

    /*-- Layout 4 style starts --*/
    &.image-text-Layout-4 {
        .bottom-description  {
            margin-top: 25px;
            @include media-breakpoint-up(sm) {
                margin-top: 25px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 35px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 50px;
            }
        }
        .vertical-alignment {
            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
        .text-block-heading {
            word-break: break-word;
        }
    }
    /*-- Layout 4 style ends --*/
    .align-bottom-heading {
        margin-bottom: 20px;
        @include media-breakpoint-only(sm) {
            margin-bottom: 40px;
        }
    }
    .layout-bottom-block {
        // @include media-breakpoint-down(sm) {
        //     margin-top: 20px;
        // }
        @include media-breakpoint-up(sm) {
            padding-top: 30px;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 40px;
        }
    }
    .custom-text-color {
        * {
            color: inherit;
        }
        a {
            color: inherit;
            &:after {
                border-color: inherit;
            }
            &:hover {
                color: inherit;
                border-color: inherit;
                &:after {
                    border-color: inherit;
                }
            }
        }
    }
}

.parallel-images {
    .content-part {
        margin-top: 20px;
        @include media-breakpoint-up(lg) {
            margin-top: 50px;
        }
        h2, h1 {
            margin-bottom: 20px;
        }
    }
}

.video-text-Layout {
    @include top-bottom-padding;
    * {
        color: $brand-secondary;
    }
    .button {
        &:after,
        &:hover {
            border-color: $brand-secondary;
            color: $brand-secondary;
            &:after {
                border-color: $brand-secondary;
            }
        }
    }
    &.video-text-Layout-1 {
        .text-block {
            padding-left: 25px;
            @include media-breakpoint-down(md) {
                padding-left: 10px;
            }
        }   
    }
    &.video-text-Layout-2 {
        .text-block {
            padding-right: 25px;
        }   
    }
    .video-part {
        video {
            width: 100%;
        }
    }
}